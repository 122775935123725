import React, { Component } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';
import styled from '@emotion/styled';
import Section from 'src/components/Section';
import IconSwipe from 'src/svg/icon-swipe.svg';
import IconSwipeSmall from 'src/svg/icon-swipe-small.svg';
import ResponsiveComponent from 'src/components/ResponsiveComponent';
import {
  colors,
  globals,
  mediaQueries as mq,
  helpers,
  typography,
} from 'src/styles';

const transitionSpeed = 500;

class CardsSlideshow extends Component {
  state = {
    slideIndex: this.props.slides.length,
    slides: this.props.slides,
    animationStatus: false,
  };

  nextSlide = () => {
    const { slides } = this.state;
    // remove item
    const lastItem = slides[slides.length - 1];
    // set state
    slides.pop();

    this.setState({ slides }, (state) => {
      setTimeout(() => {
        slides.unshift(lastItem);
        this.setState({ slides });
      }, transitionSpeed + 5);
    });
  };

  updateAnimationStatus = (status) => {
    this.setState({ animationStatus: status });
  };

  componentDidMount = () => {
    let setSlides = this.props.slides;

    setSlides.forEach((slide, index) => {
      slide.displayNumber = index + 1;
    });

    this.setState({ slides: setSlides.reverse() });
  };

  render() {
    const {
      bgColor,
      noMarginTop,
      noMarginBottom,
      prevBgColor,
      nextBgColor,
      pageColors,
    } = this.props;

    const { animationStatus, slideIndex, slides } = this.state;

    if (slides.length < 1) {
      return false;
    }

    return (
      <Section
        bgColor={bgColor}
        pageColors={pageColors}
        noMarginTop={noMarginTop}
        noMarginBottom={noMarginBottom}
        width="wide"
        prevBgColor={prevBgColor}
        nextBgColor={nextBgColor}
      >
        <StyledDiv cardCount={slides.length} animationStatus={animationStatus}>
          <div className="card-wrapper">
            <Card className="placeholder" animationStatus={animationStatus} />
            <TransitionGroup>
              {slides.map((slide, index) => (
                <Transition
                  key={'slide-' + slide.displayNumber}
                  timeout={{
                    enter: transitionSpeed,
                    exit: transitionSpeed,
                  }}
                  onEntering={() => this.updateAnimationStatus('entering')}
                  onEntered={() => this.updateAnimationStatus('entered')}
                  onExiting={() => this.updateAnimationStatus('exiting')}
                >
                  {(transitionStatus) => (
                    <Card
                      className={transitionStatus}
                      cardCount={slideIndex}
                      index={index}
                      pageColors={pageColors}
                      onClick={
                        animationStatus === 'entered' || !animationStatus
                          ? this.nextSlide
                          : undefined
                      }
                    >
                      <CardContent>
                        <div className="icon-wrap">
                          <ResponsiveComponent
                            small={<IconSwipeSmall />}
                            medium={<IconSwipe />}
                          />
                          <p>{slide.displayNumber + '/' + slideIndex}</p>
                        </div>
                        <small>{slide.item1title}</small>
                        <h4>{slide.item1text}</h4>
                        {slide.item2title && (
                          <div>
                            <hr />
                            <small>{slide.item2title}</small>
                            <h4>{slide.item2text}</h4>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  )}
                </Transition>
              ))}
            </TransitionGroup>
          </div>
        </StyledDiv>
      </Section>
    );
  }
}

const StyledDiv = styled.div`
  margin-bottom: -20px;
  margin-left: -20px;
  margin-right: -${globals.containerLargeMargins};
  margin-top: -40px;
  overflow: hidden;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: ${globals.containerLargeMargins};
  padding-top: 40px;

  ${mq.largeAndBelow} {
    margin-right: -${globals.containerMediumMargins};
    padding-right: ${globals.containerMediumMargins};
  }

  ${mq.smallAndBelow} {
    margin-right: -${globals.containerSmallMargins};
    padding-right: ${globals.containerSmallMargins};
  }

  .card-wrapper {
    position: relative;
    width: 100%;

    ${({ cardCount }) => `
      padding-top: ${cardCount - 1}rem;
      margin-right: ${cardCount - 1}rem;
    `}

    ${({ animationStatus }) =>
      animationStatus === 'exiting' &&
      `
      margin-top: 1rem;
    `}

    ${({ animationStatus }) =>
      animationStatus === 'entering' &&
      `
      margin-bottom: -1rem;
      margin-left: -1rem;
      margin-top: 1rem;
    `}

    ${({ animationStatus }) =>
      animationStatus === 'entered' &&
      `
      margin-top: 0;
      transition: margin ${transitionSpeed + 'ms'} ease-in-out;
    `}

    hr {
      ${helpers.responsiveStyles('margin-bottom', 50, 40, 30, 24)}
      ${helpers.responsiveStyles('margin-top', 45, 35, 28, 22)}
      border-color: white;
      border-width: 2px;
    }
  }

  .icon-wrap {
    ${helpers.responsiveStyles('right', 60, 50, 30, 20)}
    ${helpers.responsiveStyles('top', 60, 50, 30, 20)}
    color: white;
    position: absolute;
    text-align: center;
    width: 80px;

    svg {
      margin-left: -10%;
      vertical-align: top;
    }

    p {
      font-weight: 500;
      margin: 0.5em 0 0 0;

      ${mq.largeAndUp} {
        ${typography.bodyMedium}
      }
    }
  }

  h4 {
    font-size: ${typography.fontSizeExtraLarge2};
    font-weight: 700;
    color: white;
    margin-bottom: 0.2em;
    margin-right: 50px;
    margin-top: 0.75rem;

    ${mq.largeAndUp} {
      margin-right: 80px;
    }
  }

  small {
    ${typography.bodyMedium}
    color: white;
    font-weight: 700;
    margin-bottom: 0;
    margin-right: 50px;
    margin-top: 0.2em;

    ${mq.largeAndUp} {
      margin-right: 80px;
    }
  }
`;

const Card = styled.div`
  ${helpers.responsiveStyles('padding', 70, 60, 40, 30)}
  ${helpers.responsiveStyles('padding-top', 50, 45, 26, 16)}
  align-items: center;
  border: 4px solid
    ${({ pageColors }) =>
      pageColors ? colors[`${pageColors.mainColor}Lighten`] : 'transparent'};
  background: ${({ pageColors }) =>
    pageColors ? colors[pageColors.mainColor] : 'transparent'};
  bottom: 0;
  display: flex;
  min-height: 375px;
  left: 0;
  max-width: 700px;
  position: absolute;
  cursor: pointer;
  transition: transform ${transitionSpeed}ms cubic-bezier(1, 0.16, 0.24, 0.44),
    opacity ${transitionSpeed}ms ease-in-out;
  width: calc(55vw - ${({ cardCount }) => cardCount - 1} * 1rem);

  ${({ index }) => `
    bottom: ${index}rem;
    left: ${index}rem;
    z-index: ${index + 1};
  `}

  &.placeholder {
    opacity: 0;
    position: relative;
  }

  &.entered {
    opacity: 1;
  }

  &.entering {
    opacity: 0;
  }

  &.exiting {
    transform: translate3d(150%, 0, 0) rotate(2deg);
  }

  ${mq.largeAndBelow} {
    width: calc(90vw - ${({ cardCount }) => cardCount - 1} * 1rem);
  }
`;

const CardContent = styled.div`
  width: 100%;
`;

CardsSlideshow.defaultProps = {
  slides: [],
};

export default CardsSlideshow;
