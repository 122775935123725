import React, { useContext } from 'react'
import styled from '@emotion/styled'
import {
  colors,
  typography,
  animations,
  mediaQueries as mq,
  helpers,
} from "src/styles"
import IconArrow from 'src/svg/icon-arrow.svg'
import BgSelector from 'src/components/BgSelector'
import Link from 'src/components/Link'
import Image from 'src/components/Image'
import ScrollEntrance from 'src/components/ScrollEntrance'
import GridStyles from 'src/components/GridStyles'
import PageContext from 'src/context/PageContext';

const StyledLink = styled(Link)`
  position: relative;
  display: block;
`
const Grid = styled(GridStyles.Grid) `
`
const GridColumn = styled(GridStyles.GridColumn) `
  ${ mq.largerAndUp } {
    -ms-grid-column-span: 8;
    -ms-grid-column: 5;
    grid-column: 5 / span 8;
  }
`
const NextSectionText = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  min-width: 240px;
  margin: 0;
  color: ${ colors.bgColor };
  display: flex;
  align-items: center;

  ${ mq.mediumAndBelow } {
    min-width: 225px;
  }
  transition: transform .25s cubic-bezier(0.44, 0.24, 0.16, 1.00);

  ${StyledLink}:hover &,
  ${StyledLink}:focus & {
    transform: translate3d(45px, 0, 0);
  }
`
const HorizontalEntrance = styled(ScrollEntrance)`
  width: 100%;
  overflow: hidden;
`
const NextSectionHeader = styled.div`
  ${ typography.bodyLarge }
  margin: 0;
  font-weight: 500;
  display: inline-block;
  padding: 14px 14px 0;
  align-items: center;
  background-color: ${ ({ color }) => colors[color] };
  transition: background-color ${ animations.mediumSpeed } ease-in-out;
  ${ mq.mediumAndBelow } {
    padding: 10px 10px 0;
    font-weight: 500;
  }
  ${StyledLink}:hover & {
    background-color: ${ ({ color }) => colors[`${ color }Darken`] };
  }
`
const LargeArrow = styled(IconArrow) `
  margin-left: 10px;
  margin-top: -3px;
  vertical-align: middle;
  height: auto;
  ${ mq.mediumAndBelow } {
    width: 16px;
  }
`
const NextSectionTitle = styled.div`
  background: ${ ({ color }) => colors[color] };
  display: inline-block;
  transition: background ${ animations.mediumSpeed } ease-in-out;
  padding: 14px;
  margin-top: -2px;
  min-width: 80%;
  ${ mq.mediumAndBelow } {
    padding: 10px;
  }
  ${StyledLink}:hover & {
    background-color: ${ ({ color }) => colors[`${ color }Darken`] };
  }
`
const Paragraph = styled.p`
  margin: 0;
  line-height: 1.3em;
  ${ helpers.responsiveStyles('font-size', 22, 18, 18, 18) }
`
const ImageWrapper = styled.div`
  position: relative;
  margin-left: 20%;
  z-index: 1;

  transition: transform .25s cubic-bezier(0.44, 0.24, 0.16, 1.00) !important;
  ${StyledLink}:hover &,
  ${StyledLink}:focus & {
    transform: scale(1.01);
  }
`
const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color ${ animations.mediumSpeed } ease-in-out;
  ${StyledLink}:hover & {
    background-color: rgba(0, 0, 0, .1);
  }
`

export default function NextSection ({ title, image, link, bgColor, pageColors }) {
  const mainColor = pageColors.mainColor;
  const { lang } = useContext(PageContext);
  return (
    <BgSelector bgColor={bgColor} pageColors={pageColors}>
      <Grid>
        <GridColumn>
          <StyledLink to={link}>
            <NextSectionText>
              <HorizontalEntrance transform={{x: -30}}>
                <NextSectionHeader color={mainColor}>
                  <span>{lang === 'es' ? 'Siguiente' : 'Next'}</span> 
                  <LargeArrow />
                </NextSectionHeader>
                <div>
                  <NextSectionTitle color={mainColor}>
                    <Paragraph dangerouslySetInnerHTML={{ __html: title }} />
                  </NextSectionTitle>
                </div>
              </HorizontalEntrance>
            </NextSectionText>
            <ImageWrapper>
              <HorizontalEntrance transform={{x: 30}}>
                <Image image={image} imgStyle={{ margin: 0, display: 'block' }} />
                <ImageOverlay />
              </HorizontalEntrance>
            </ImageWrapper>
          </StyledLink>
        </GridColumn>
      </Grid>
    </BgSelector>
  )

}
