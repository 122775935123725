import React from 'react';
import styled from '@emotion/styled';
import Image from 'src/components/Image';
import Parallax from 'src/components/Parallax';
import Section from 'src/components/Section';
import { mediaQueries as mq, globals } from 'src/styles';
import Hexagon from 'src/svg/shape-hexagon.svg';
import Slash from 'src/svg/slash.svg';
import Rotate from 'react-reveal/Rotate';

const Wrapper = styled.div`
  margin-right: -${globals.containerLargeMargins};
  ${mq.largeAndBelow} {
    margin-right: -${globals.containerMediumMargins};
    margin-left: -${globals.containerMediumMargins};
  }
  ${mq.smallAndBelow} {
    margin-right: -${globals.containerSmallMargins};
    margin-left: -${globals.containerSmallMargins};
  }
  ${({ shape }) => shape === 'slash' && `margin-bottom: 6%;`}
`;

const Layer = styled(Parallax)`
  ${({ layerIndex }) =>
    layerIndex !== 1
      ? `
    position: absolute;
  `
      : `
    position: relative;
    text-align: right;
    .gatsby-image-wrapper {
      ${mq.mediumAndBelow} {
        float: right;
        width: 95%;
        margin-left: 0;
      }
    }
  `}
  z-index: ${({ layerIndex }) => layerIndex};
  left: 0;
  width: 100%;
  overflow: hidden;
  ${({ shape }) =>
    shape &&
    `
    width: auto;
    height: auto;
  `}
  ${({ shape }) =>
    shape === 'hexagon' &&
    `
    width: 34vw;
    ${mq.mediumAndBelow} {
      width: 45vw;
    }
    svg {
      width: 100%;
      height: auto;
    }
  `}
  ${({ shape, layer2 }) =>
    shape === 'slash' &&
    `
    width: 14%;
    top: 80%;
    padding: 5% 0;
    ${
      layer2
        ? `
      left: 10%;
    `
        : `
      left: 50%;
      margin-left: -10%;
    `
    }
    svg {
      width: 100%;
      height: auto;
    }
  `}
`;

const ImageCollage = ({
  layer1,
  layer2,
  bgColor,
  shape,
  isFirst,
  pageColors,
}) => (
  <Section
    noMarginBottom
    noMarginTop={!isFirst}
    zIndex={2}
    width="extra-wide"
    bgColor={bgColor}
    pageColors={pageColors}
  >
    <Wrapper style={{ position: 'relative' }} shape={shape}>
      <Layer layerIndex={3} posYStart={340} posYEnd={-100}>
        <Image image={{ image: layer2 }} />
      </Layer>
      <Layer layerIndex={1}>
        <Image image={{ image: layer1 }} />
      </Layer>
      {shape === 'hexagon' && (
        <Layer
          shape={shape}
          layerIndex={0}
          posYStart={25}
          posYEnd={-25}
          rotateStart={200}
          rotateEnd={0}
          scrollUnit="%"
        >
          <Hexagon />
        </Layer>
      )}
      {shape === 'slash' && (
        <Layer
          layer2={!!layer2}
          shape={shape}
          layerIndex={4}
          posYStart={0}
          posYEnd={0}
          rotateStart={0}
          rotateEnd={0}
          scrollUnit="%"
        >
          <Rotate>
            <Slash />
          </Rotate>
        </Layer>
      )}
    </Wrapper>
  </Section>
);

export default ImageCollage;
