import React from 'react';

import TextBlock from 'src/components/TextBlock';
import FactBox from 'src/components/FactBox';
import VideoCaseStudyBlock from 'src/components/VideoCaseStudyBlock';
import ReadMoreBlock from 'src/components/ReadMoreBlock';
import ImageCollage from 'src/components/ImageCollage';
import MediaCollage from 'src/components/MediaCollage';
import ImageOverlap from 'src/components/ImageOverlap';
import ArrowLink from 'src/components/ArrowLink';
import ActivityBlock from 'src/components/ActivityBlock';
import AudioPlayer from 'src/components/AudioPlayer';
import CardsSlideshow from 'src/components/CardsSlideshow';
import ImageCaptionBlock from 'src/components/ImageCaptionBlock';

const componentMap = {
  DesignPage_Designpagesmodules_ContentBlocks_BlockText: TextBlock,
  DesignPage_Designpagesmodules_ContentBlocks_ImageCollage: ImageCollage,
  DesignPage_Designpagesmodules_ContentBlocks_MediaCollage: MediaCollage,
  DesignPage_Designpagesmodules_ContentBlocks_Video: VideoCaseStudyBlock,
  DesignPage_Designpagesmodules_ContentBlocks_CardDeck: CardsSlideshow,
  DesignPage_Designpagesmodules_ContentBlocks_ReadMoreLinks: ReadMoreBlock,
  DesignPage_Designpagesmodules_ContentBlocks_OverlappingImages: ImageOverlap,
  DesignPage_Designpagesmodules_ContentBlocks_ActivityBlock: ActivityBlock,
  DesignPage_Designpagesmodules_ContentBlocks_LargeArrowLink: ArrowLink,
  DesignPage_Designpagesmodules_ContentBlocks_AudioPlayer: AudioPlayer,
  DesignPage_Designpagesmodules_ContentBlocks_ImageWithCaption:
    ImageCaptionBlock,
  DesignPage_Designpagesmodules_ContentBlocks_FactBox: FactBox,
};

const componentRenderer = ({ item, pageColors }) => {
  const Component = componentMap[item.fieldGroupName];
  return Component ? <Component {...item} pageColors={pageColors} /> : null;
};

export default componentRenderer;
