import React from 'react'
import styled from '@emotion/styled'
import Container from 'src/components/Container'
import BgSelector from 'src/components/BgSelector'
import { globals, helpers, mediaQueries as mq } from 'src/styles'
import GridStyles from 'src/components/GridStyles'

const SectionWrapper = styled(BgSelector)`
  position: relative;
  z-index: ${ ({ zIndex }) => zIndex || 1 };
  ${ ({ noMarginBottom }) => !noMarginBottom ? `
    ${ globals.verticalPaddingBottom }
    ${ ({ prevBgColor, bgColor, evenPadding }) => (prevBgColor === bgColor || evenPadding) && `
      ${ helpers.responsiveStyles('padding-bottom', 60, 45, 35, 20) }
    ` }
  ` : `
    margin-bottom: 0;
    padding-bottom: 0;
  `}

  ${ ({ noMarginTop }) => !noMarginTop ? `
   ${ globals.verticalPaddingTop }
    ${ ({ prevBgColor, bgColor, evenPadding }) => (prevBgColor === bgColor || evenPadding) && `
      ${ helpers.responsiveStyles('padding-top', 60, 45, 35, 20) }
    ` }
  ` : `
    margin-bottom: 0;
    padding-bottom: 0;
  `}
  ${ ({ isFirst }) => isFirst ? helpers.responsiveStyles('padding-top', 60, 45, 80, 80) : false }
`
const columnWidths = (width) => {
  switch(width) {
    case 'wide' :
      return `
        ${ mq.largerAndUp } {
          -ms-grid-column-span: 8;
          -ms-grid-column: 5;
          grid-column: 5 / span 8;
        }
      `;
    case 'extra-wide' :
      return `
        ${ mq.largeAndUp } {
          -ms-grid-column-span: 9;
          -ms-grid-column: 4;
          grid-column: 4 / span 9;
        }
      `;
    case 'wide-center' :
      return `
        ${ mq.largeAndUp } {
          -ms-grid-column-span: 8;
          -ms-grid-column: 3;
          grid-column: 3 / span 8;
        }
      `;
    default :
      return `
        ${ mq.largeAndUp } {
          -ms-grid-column-span: 8;
          -ms-grid-column: 5;
          grid-column: 5 / span 8;
        }
        ${ mq.largerAndUp } {
          -ms-grid-column-span: 7;
          -ms-grid-column: 5;
          grid-column: 5 / span 7;
        }
      `;
  }
}
const Grid = styled(GridStyles.Grid) `
`
const GridColumn = styled(GridStyles.GridColumn) `
  ${({width}) => columnWidths(width)}
`

const Section = ({ children, width, bgColor, prevBgColor, nextBgColor, noMarginBottom, noMarginTop, zIndex, className, pageColors, evenPadding, isFirst }) => {
  return (
    <SectionWrapper
      evenPadding={evenPadding}
      bgColor={bgColor}
      pageColors={pageColors}
      prevBgColor={prevBgColor}
      nextBgColor={nextBgColor}
      noMarginBottom={noMarginBottom}
      noMarginTop={noMarginTop}
      zIndex={zIndex}
      className={className}
      isFirst={isFirst}
    >
      <Container>
        <Grid >
          <GridColumn width={width}>
            {children}
          </GridColumn>
        </Grid>
      </Container>
    </SectionWrapper>
  )
}

Section.defaultProps = {
  bgColor: 'default',
  evenPadding: false,
}

export default Section
