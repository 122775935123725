import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { colors, helpers, typography, animations, mediaQueries as mq } from 'src/styles'
import Container from 'src/components/Container'
import LargeArrow from 'src/svg/icon-arrow.svg'
import Image from 'src/components/Image'
import BgSelector from 'src/components/BgSelector'
import Link from 'src/components/Link'
import { useInView } from 'react-intersection-observer'
import PageContext from 'src/context/PageContext';

const NextSection = styled(BgSelector)`
  position: relative;
  padding: 100px 0;

  ${ mq.mediumAndBelow } {
    padding: 20px 0;
  }
`

const transitionDelayBase = 0.3

const ImageWrapper = styled.div`
  ${ ({ inView }) => inView ? `
    transition: transform 0.65s cubic-bezier(0.44, 0.24, 0.16, 1.00), opacity 0.65s cubic-bezier(0.44, 0.24, 0.16, 1.00);
    transition-delay: ${ transitionDelayBase }s;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  ` : `
    transform: translate3d(-15%, 0, 0);
    opacity: 0;
  ` }
  position: relative;
  left: 10%;
  width: 50%;
  overflow: hidden;

  ${ mq.mediumAndBelow } {
    left: 0;
    width: 75%;
  }

  .image-wipe {
    background: white;
    position: absolute;
    ${ ({ inView }) => inView ? `
      right: -140%;
      transform: rotate(15deg);
      transition: right 1.2s ease-out, transform 1.2s linear;
      transition-delay: ${ transitionDelayBase + 0.3 }s;
    ` : `
      right: 0;
      transform: rotate(0deg);
    ` }
    top: -10%;
    height: 140%;
    width: 120%;
    z-index: 1;
  }
`

const TextWrapper = styled.div`
  position: absolute;
  width: 45%;
  right: 0;
  top: 150px;
  z-index: 2;
  ${ ({ inView }) => inView ? `
    transition: transform 0.65s cubic-bezier(0.44, 0.24, 0.16, 1.00), opacity 0.65s cubic-bezier(0.44, 0.24, 0.16, 1.00);
    transform: translate3d(0, 0, 0);
    transition-delay: ${ transitionDelayBase + 0.7 }s;
    opacity: 1;
  ` : `
    transform: translate3d(-15%, 0, 0);
    opacity: 0;
  ` }

  ${ mq.mediumAndBelow } {
    position: relative;
    top: -50px;
    margin-left: auto;
    width: 75%;
  }
`

const TextLink = styled(Link)`
  background: ${ ({ color }) => colors[color] };
  display: block;
  color: ${ colors.bgColor };
  transition: background ${ animations.mediumSpeed } ease-in-out;
  padding: 70px 0 60px;

  &:hover {
    background: ${ ({ color }) => colors[`${ color }Darken`] };
  }

  ${ mq.mediumAndBelow } {
    padding: 30px 0 30px;
  }
`

const NextSectionHeader = styled.div`
  ${ typography.bodyLarge }
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em;
  ${ typography.h3 }
  font-weight: bold;
  svg {
    ${ helpers.responsiveStyles('width', 50, 40, 36, 20) }
    height: auto;
    margin-left: 20px;
    margin-top: -1px;
    vertical-align: middle;
    * {
      fill: currentcolor;
    }
  }
`

const NextSectionTitle = styled.h3`
  margin: 0;
  ${ typography.h3 }
  font-weight: bold;
`

const NextSectionLarge = ({ title, image, link, bgColor, pageColors, textAccent }) => {
  const [ref, inView] = useInView()
  const { lang } = useContext(PageContext);
  return (
    <NextSection bgColor={bgColor} pageColors={pageColors}>
      <div ref={ref}>
        <ImageWrapper inView={inView}>
          <Image image={image} animate={false} />
          <div className="image-wipe" />
        </ImageWrapper>
        <TextWrapper inView={inView}>
          <TextLink to={link} color={textAccent}>
            <Container>
              <NextSectionHeader>{lang === 'es' ? 'Siguiente módulo' : 'Next Module'}<LargeArrow /></NextSectionHeader>
              <NextSectionTitle>{title}</NextSectionTitle>
            </Container>
          </TextLink>
        </TextWrapper>
      </div>
    </NextSection>
  )
}

export default NextSectionLarge
