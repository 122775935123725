import React from 'react';
import withSizes from 'react-sizes';
import { mediaQueries } from 'src/styles';

const widthToRenderer = (winWidth, small, medium, large) => {
  if (large && winWidth > mediaQueries.largeBreakpoint) {
    return large;
  }

  if (medium && winWidth > mediaQueries.mediumBreakpoint) {
    return medium;
  }

  return small || medium || large;
};

const ResponsiveComponent = ({ winWidth, small, medium, large }) => (
  <>{widthToRenderer(winWidth, small, medium, large)}</>
);

const sizesToProps = ({ width, height }) => ({
  winWidth: width,
});

export default withSizes(sizesToProps)(ResponsiveComponent);
