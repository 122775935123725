import React from 'react';
import { withPrefix } from 'gatsby';
import styled from '@emotion/styled';
import {
  colors,
  helpers,
  typography,
  mediaQueries as mq,
  globals,
} from 'src/styles';
import Section from 'src/components/Section';
import ConditionalRender from 'src/components/ConditionalRender';
import ScrollEntrance from 'src/components/ScrollEntrance';
import DownloadButton from 'src/components/DownloadButton';
import CircleVideo from 'src/components/CircleVideo';

const ActivityBlock = (props) => {
  const {
    addIcon,
    eyebrow,
    headline,
    card,
    bgColor,
    text,
    showButton,
    buttonLabel,
    buttonPDF,
    noMarginTop,
    noMarginBottom,
    prevBgColor,
    nextBgColor,
    pageColors,
  } = props;

  return (
    <Section
      bgColor={bgColor}
      pageColors={pageColors}
      width="wide"
      noMarginTop={noMarginTop}
      noMarginBottom={noMarginBottom}
      prevBgColor={prevBgColor}
      nextBgColor={nextBgColor}
    >
      <StyledDiv card={card} bgColor={bgColor} pageColors={pageColors}>
        {addIcon && (
          <CircleVideo url={withPrefix('/videos/analyze_to_size.mp4')} />
        )}
        <ScrollEntrance className="horizontal-entrance" transform={{ x: 30 }}>
          <ConditionalRender condition={eyebrow}>
            <h5>{eyebrow}</h5>
          </ConditionalRender>
          <ConditionalRender condition={headline}>
            <h3 className="headline">{headline}</h3>
          </ConditionalRender>
        </ScrollEntrance>
        <ScrollEntrance className="copy">
          <ConditionalRender condition={text}>
            <div
              className="rich-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </ConditionalRender>
        </ScrollEntrance>
        {showButton && (
          <DownloadButton
            buttonLabel={buttonLabel}
            buttonUrl={buttonPDF.localFile.publicURL}
            pageColors={pageColors}
          />
        )}
      </StyledDiv>
    </Section>
  );
};

const StyledDiv = styled.div`
  ${({ card, bgColor, pageColors }) =>
    card &&
    `
    ${helpers.responsiveStyles('padding-bottom', 100, 85, 80, 50)}
    ${helpers.responsiveStyles('padding-left', 140, 100, 50, 20)}
    ${helpers.responsiveStyles('padding-right', 140, 100, 50, 20)}
    ${helpers.responsiveStyles('padding-top', 100, 85, 80, 50)}
    background: ${
      bgColor === 'lightBlue'
        ? colors.bgColor
        : colors[`${pageColors.mainColor}Lighten`]
    };
    margin-right: -${globals.containerLargeMargins};
    position: relative;

    ${mq.largeAndBelow} {
      ${helpers.responsiveStyles('margin-top', 100, 80, 70, 50)}
      margin-right: -${globals.containerMediumMargins};
    }

    ${mq.smallAndBelow} {
      margin-left: -${globals.containerSmallMargins};
      margin-right: -${globals.containerSmallMargins};
      padding-left: ${globals.containerSmallMargins};
      padding-right: ${globals.containerSmallMargins};
    }
  `}

  .horizontal-entrance {
    max-width: 100%;
    overflow: hidden;
    width: 100%;
  }

  .headline {
    color: ${({ pageColors }) => colors[pageColors.mainColor]};
    font-weight: bold;
    margin-bottom: 0.75em;
  }

  .rich-text {
    hr {
      border-color: ${({ pageColors }) => colors[pageColors.mainColor]};
      border-width: 2px;
      margin: 3em 0;
    }

    p,
    li {
      ${typography.bodyMedium}
      margin-bottom: 2em;
      margin-top: 0;
    }

    ol,
    ul {
      b,
      strong {
        font-weight: 700;
      }

      li {
        padding-left: 2em;
        position: relative;

        ${mq.largerAndUp} {
          padding-left: 2em;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
        }
      }
    }

    ul {
      li {
        &::before {
          background: currentcolor;
          border-radius: 50%;
          height: 8px;
          left: 0;
          margin-top: -1px;
          position: absolute;
          top: 0.5em;
          width: 8px;
        }
      }
    }

    ol {
      counter-reset: a;

      li {
        &::before {
          content: counter(a) '.';
          counter-increment: a;
          font-weight: 700;
          left: 0;
          position: absolute;
          top: 0;
        }
      }
    }

    h5 {
      ${typography.bodyMedium}
      font-weight: 700;
    }
  }

  .copy {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: ${({ pageColors }) => colors[pageColors.mainColor]};
      font-weight: bold;
      margin-bottom: 0.2em;
    }
  }

  .shape-wrap {
    left: ${globals.containerSmallMargins};
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    ${mq.mediumAndUp} {
      left: 9%;
    }

    ${mq.largeAndUp} {
      left: 8%;
    }

    ${mq.largerAndUp} {
      ${helpers.responsiveStyles('padding-top', 90, 90, 90, 60)}
      left: 0;
      top: 0;
      transform: translateX(-50%);
    }
  }

  .badge {
    ${helpers.responsiveStyles('height', 175, 160, 120, 88)}
    ${helpers.responsiveStyles('width', 175, 160, 120, 88)}
    align-items: center;
    background: ${({ pageColors }) => colors[pageColors.mainColor]};
    border-radius: 50%;
    display: block;
    display: flex;
    height: auto;
    justify-content: center;
    padding-right: 6%;

    svg {
      display: block;
      height: auto;
      width: 43%;

      * {
        fill: ${colors.bgColor};
      }
    }
  }

  .download-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ pageColors }) => colors[pageColors.mainColor]};

    svg {
      height: 50px;
      width: 50px;

      ${mq.mediumAndUp} {
        height: 80px;
        width: 80px;
      }
    }

    span {
      ${typography.h4}
      font-weight: 700;
      line-height: 1;
      padding: 0 0.75rem;
      width: 25%;
    }

    &:hover {
      color: ${({ pageColors }) => colors[`${pageColors.mainColor}Darken`]};
    }
  }
`;

ActivityBlock.defaultProps = {
  bgColor: 'lightBlue',
  card: true,
};

export default ActivityBlock;
