import React from 'react';
import styled from '@emotion/styled';
import {
  colors,
  helpers,
  typography,
  mediaQueries as mq,
  globals,
} from 'src/styles';
import ScrollEntrance from 'src/components/ScrollEntrance';
import ConditionalRender from 'src/components/ConditionalRender';
import Parallax from 'src/components/Parallax';
import Section from 'src/components/Section';
import Shape from 'src/svg/shape-hexagon.svg';
import DownloadButton from 'src/components/DownloadButton';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const TextBlockWrapper = styled.div`
  position: relative;
`;
const TextBlockContent = styled(ReactMarkdown)`
  ${({ headline }) =>
    headline && helpers.responsiveStyles('margin-top', 60, 40, 35, 30)}

  *:first-of-type {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  p {
    ${typography.bodyMedium}
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ pageColors }) => colors[pageColors.mainColor]};
  }

  h1,
  h2,
  h3 {
    margin-top: 1em;
  }

  h4,
  h5 {
    margin-top: 1.75em;
  }

  blockquote {
    ${typography.bodyLarge}
    ${globals.verticalMargins}
    color: ${({ pageColors }) => colors[pageColors.mainColor]};
    padding-left: 1.5em;
    position: relative;

    ${mq.mediumAndUp} {
      padding-left: 2.5em;
    }

    p {
      ${typography.bodyLarge}
      font-weight: 500;
    }

    &::before {
      content: '';
      border-radius: 0 100px 100px 0;
      border: 0.375em solid currentColor;
      border-left: none;
      display: block;
      height: 0.7em;
      left: 0;
      position: absolute;
      top: 0.3em;
      width: 0.35em;

      ${mq.mediumAndUp} {
        border-width: 0.65em;
        height: 1em;
        top: 0.15em;
        width: 0.5em;
      }
    }
  }

  p,
  .gatsby-image-wrapper {
    ${typography.bodyMedium}
    ${helpers.responsiveStyles('margin-bottom', 60, 40, 35, 30)}
    ${helpers.responsiveStyles('margin-top', 60, 40, 35, 30)}
    margin-bottom: 2em;
    margin-top: 0;
  }

  li {
    ${typography.bodyMedium}
    margin-bottom: 1.5em;
  }

  .gatsby-image-wrapper {
    ${helpers.responsiveStyles('max-width', 800, 750, 700, 600)}
  }

  ol,
  ul {
    list-style: none;
    padding-left: 0;

    ul,
    ol {
      ${helpers.responsiveStyles('padding-top', 35, 35, 35, 30)}

      li {
        ${helpers.responsiveStyles('margin-bottom', 35, 35, 35, 30)}
        ${helpers.responsiveStyles('margin-top', 35, 35, 35, 30)}
      }
    }

    > li {
      padding-left: 2em;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
      }
    }
  }

  ul {
    > li {
      &::before {
        background: ${({ pageColors }) => colors[pageColors.mainColor]};
        border-radius: 50%;
        height: 8px;
        left: 0;
        margin-top: -1px;
        position: absolute;
        top: 0.5em;
        width: 8px;
      }
    }
  }

  ol {
    counter-reset: a;

    > li {
      &::before {
        color: ${({ pageColors }) => colors[pageColors.mainColor]};
        content: counter(a) '.';
        counter-increment: a;
        font-weight: 700;
        left: 0;
        position: absolute;
        top: 0;
      }
    }
  }
`;
const StyledDownloadButton = styled.div`
  margin-top: 20px;
`;
const Eyebrow = styled.h5`
  color: ${colors.textColor};
`;
const Headline = styled.h3`
  font-weight: bold;
  ${({ eyebrow }) => !eyebrow && `margin-top: 0;`}
  color: ${({ pageColors }) => colors[pageColors.mainColor]};
`;
const BgShape = styled.div`
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translate3d(-50%, -50%, 0);
`;
const BgSvg = styled(Shape)`
  color: ${({ bgcolor, color }) =>
    bgcolor !== 'white' ? color.white : colors[`${color}Lighten`]};
  width: 100px;
  ${mq.mediumAndUp} {
    width: 219px;
  }
`;
const HorizontalEntrance = styled(ScrollEntrance)`
  overflow: hidden;
`;
const TextBlock = (props) => {
  const {
    eyebrow,
    headline,
    text,
    bgColor,
    showShape,
    noMarginTop,
    noMarginBottom,
    prevBgColor,
    nextBgColor,
    showButton,
    buttonPDF,
    buttonLabel,
    pageColors,
    isFirst,
  } = props;

  return (
    <Section
      bgColor={bgColor}
      pageColors={pageColors}
      noMarginTop={noMarginTop}
      noMarginBottom={noMarginBottom}
      prevBgColor={prevBgColor}
      nextBgColor={nextBgColor}
      isFirst={isFirst}
    >
      <TextBlockWrapper pageColors={pageColors} headline={headline}>
        <HorizontalEntrance transform={{ x: 30 }}>
          <ConditionalRender condition={eyebrow}>
            <Eyebrow>{eyebrow}</Eyebrow>
          </ConditionalRender>
          <ConditionalRender condition={headline}>
            <Headline eyebrow={eyebrow} pageColors={pageColors}>
              {headline}
            </Headline>
          </ConditionalRender>
        </HorizontalEntrance>
        <ConditionalRender condition={text}>
          <ScrollEntrance>
            <TextBlockContent
              pageColors={pageColors}
              headline={headline}
              rehypePlugins={[rehypeRaw]}
              children={text}
            />
            {showButton && buttonPDF && (
              <StyledDownloadButton>
                <DownloadButton
                  buttonLabel={buttonLabel}
                  buttonUrl={buttonPDF.localFile.publicURL}
                  pageColors={pageColors}
                />
              </StyledDownloadButton>
            )}
          </ScrollEntrance>
        </ConditionalRender>
        <ConditionalRender condition={showShape}>
          <div>
            <BgShape>
              <Parallax
                rotateStart={30}
                rotateEnd={-135}
                posYStart={65}
                posYEnd={-65}
                scrollUnit="%"
              >
                <BgSvg bgcolor={bgColor} color={pageColors.mainColor} />
              </Parallax>
            </BgShape>
          </div>
        </ConditionalRender>
      </TextBlockWrapper>
    </Section>
  );
};

export default TextBlock;
