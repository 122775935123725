import React from 'react'
import { withPrefix } from 'gatsby'
import styled from '@emotion/styled'
import { colors, helpers, mediaQueries as mq, globals } from 'src/styles'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Parallax from 'src/components/Parallax'
import Section from 'src/components/Section'
import CircleVideo from 'src/components/CircleVideo'
import ElbowIcon from 'src/svg/icon-elbow-flipped.svg'
import ReadMoreLink from 'src/components/ReadMoreLink'

const Wrapper = styled.div`
  ${ ({ bgColor, lightColor }) => bgColor && `
    background: ${ bgColor === 'lightBlue' ? colors.bgColor : lightColor };
    position: relative;
    padding: 40px 20px 20px;
    margin: 0 -${ globals.containerSmallMargins };

    ${ mq.mediumAndUp } {
      padding: 50px 75px 40px;
      margin-left: 0;
      margin-right: -${ globals.containerMediumMargins };
    }

    ${ mq.largeAndUp } {
      padding: 75px 100px 60px;
      margin-right: -${ globals.containerLargeMargins };
    }
  `}
`
const ListItem = styled.li`
  ${ helpers.responsiveStyles('margin-bottom', 80, 70, 50, 30) }
  ${ helpers.responsiveStyles('margin-top', 80, 70, 50, 30) }
`
const ShapeWrap = styled(Parallax)`
  position: absolute;
  top: 0;
  right: 100%;
`
const Icon = styled(ElbowIcon)`
  ${ helpers.responsiveStyles('width', 70, 50, 40, 20) }
  display: block;
  height: auto;
`
export default function ReadMoreBlock({
  eyebrow,
  headline,
  items,
  nextBgColor,
  pageColors,
  prevBgColor,
  bgColor,
  addIcon
}) {

  const { mainColor } = pageColors;
  const color = colors[mainColor];
  const lightColor = colors[`${mainColor}Lighten`];

  const renderListItem = (item, i) => {
    return (
      <ListItem key={i}>
        <ScrollEntrance>
          <ReadMoreLink {...item} mainColor={pageColors.mainColor} />
        </ScrollEntrance>
      </ListItem>
    )
  }

  return (
    <Section bgColor={bgColor} pageColors={pageColors} width="wide" prevBgColor={prevBgColor} nextBgColor={nextBgColor}>
      <Wrapper bgColor={bgColor} lightColor={lightColor}>
        {addIcon && (
          <CircleVideo url={withPrefix('/videos/dive_to_size.mp4')} />
        )}
        <ScrollEntrance>
          {eyebrow && (
            <h5 style={{color}}>{eyebrow}</h5>
          )}
          {headline && (
            <h3 style={{color, fontWeight: 700}}>{headline}</h3>
          )}
        </ScrollEntrance>
        <ScrollEntrance>
          <ol>
            {items?.map(renderListItem)}
          </ol>
        </ScrollEntrance>
        {bgColor === 'lightBlue' && (
          <ShapeWrap posYStart={400} scrollUnit="%">
            <Icon style={{color}} />
          </ShapeWrap>
        )}
      </Wrapper>
    </Section>
  )
}

ReadMoreBlock.propTypes = {
}
