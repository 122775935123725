import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Video from 'src/components/Video'
import { mediaQueries as mq } from 'src/styles'

const CircleVideo = (props) => (
  <StyledDiv>
    <Video video={{ file: props.url }} />
  </StyledDiv>
)

const StyledDiv = styled.div`
  > div {
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    top: 60px;
    left: -68px;
    height: 136px;
    width: 136px;
    z-index: 1;

    ${ mq.largeAndBelow } {
      position: relative;
      top: 0;
      left: 0;
      margin-bottom: 30px;
      will-change: transform;
    }
  }

  video {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 105%;
    width: 105%;
    transform: translate(-50%, -50%);
  }
`

CircleVideo.propTypes = {
  url: PropTypes.string.isRequired,
}

export default CircleVideo