import React, { Component } from 'react'
import styled from '@emotion/styled'
import { fontSmoothing } from 'src/styles/helpers'
import { animations, colors, helpers } from 'src/styles'
import MaterialIcon from 'src/components/MaterialIcon'
import Link from 'src/components/Link'

const buttonSizes = {
  tiny: '24px',
  small: '40px',
  medium: '50px',
  large: '66px',
}

const getState = (loading, error, success, disabled) => {
  let buttonState = ''
  if (error) {
    buttonState = 'error'
  } else if (loading) {
    buttonState = 'loading'
  } else if (success) {
    buttonState = 'success'
  } else if (disabled) {
    buttonState = 'disabled'
  }

  return buttonState
}

const ButtonStyles = (state, shape, size, pageColors) => (`
  appearance: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  background: ${ pageColors ? colors[pageColors.mainColor] : colors.green };
  border: 2px solid ${ pageColors ? colors[pageColors.mainColor] : colors.green };
  height: ${ buttonSizes.medium };
  padding: 0 .5em 1px;
  cursor: pointer;
  line-height: 1em;
  text-transform: none;
  letter-spacing: 0;
  border-radius: 0;
  color: ${ colors.bgColor };
  ${ helpers.responsiveStyles('font-size', 22, 20, 16, 14) }
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  box-shadow: none;
  ${ fontSmoothing }
  transition: background ${ animations.mediumSpeed } ease-in-out,
              color ${ animations.mediumSpeed } ease-in-out,
              border ${ animations.mediumSpeed } ease-in-out,
              box-shadow ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out,
              opacity ${ animations.mediumSpeed } ease-in-out;
  // Button States
  ${ state === 'disabled' ? `cursor: not-allowed;` : `` }
  ${ state === 'loading' ? `cursor: wait;` : `` }
  ${ state === 'error' ? `cursor: default;` : `` }
  ${ state !== 'disabled' ? `
    &:hover {
      background: ${ pageColors ? colors[`${pageColors.mainColor}Darken`] : colors.greenDarken };
      border-color: ${ pageColors ? colors[`${pageColors.mainColor}Darken`] : colors.greenDarken };
      transform: translateX(5px);
    }
  ` : `` }

  ${ shape === 'circle' || shape === 'square' ? `
    padding: 0;
    min-width: 0;
    width: ${ buttonSizes.medium };
    min-width: ${ buttonSizes.medium };
    ${ size === 'tiny' ? `
      width: ${ buttonSizes.tiny };
      min-width: ${ buttonSizes.tiny };
    ` : `` }
    ${ size === 'small' ? `
      width: ${ buttonSizes.small };
      min-width: ${ buttonSizes.small };
    ` : `` }
    ${ size === 'large' ? `
      width: ${ buttonSizes.large };
      min-width: ${ buttonSizes.large };
    ` : `` }
  ` : `` }

  ${ shape === 'circle' ? `border-radius: 50%;` : `` }

  ${ size === 'tiny' ? `
    height: ${ buttonSizes.tiny };
    ${ helpers.responsiveStyles('font-size', 16, 14, 13, 13) }
  ` : `` }
  ${ size === 'small' ? `
    ${ helpers.responsiveStyles('height', 40, 40, 32, 28) }
  ` : `` }
  ${ size === 'large' ? `
    height: ${ buttonSizes.large };
  ` : `` }
`)

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    * {
      fill: currentcolor;
    }
  }
`

const StyledButtonLink = styled(Link)`
  ${ props => ButtonStyles(getState(props.loading, props.error, props.success, props.disabled), props.shape, props.size, props.pageColors) }
`

const StyledButtonElement = styled.button`
  ${ props => ButtonStyles(getState(props.loading, props.error, props.success, props.disabled), props.shape, props.size, props.pageColors) }
`

class Button extends Component {
  renderIcon = icon => {
    let renderedIcon = false
    if (typeof icon === 'string') {
      renderedIcon = <MaterialIcon size={this.props.size === 'tiny' ? '18px' : 'false'}>{icon}</MaterialIcon>
    } else {
      renderedIcon = icon
    }
    return renderedIcon
  }

  render () {
    const {
      children,
      className,
      disabled,
      error,
      external,
      icon,
      iconPosition,
      loading,
      onClick,
      pageColors,
      shape,
      size,
      success,
      target,
      bgColor,
      to,
    } = this.props

    if (to) {
      return (
        <StyledButtonLink
          className={className}
          disabled={disabled}
          error={error}
          external={external}
          icon={icon}
          iconPosition={iconPosition}
          loading={loading}
          onClick={onClick}
          pageColors={pageColors}
          shape={shape}
          size={size}
          success={success}
          target={target}
          bgColor={bgColor}
          to={to}
        >
          <ButtonContent>
            {icon && iconPosition !== 'right' ? this.renderIcon(icon) : false}
            {children}
            {icon && iconPosition === 'right' ? this.renderIcon(icon) : false}
          </ButtonContent>
        </StyledButtonLink>
      )
    } else {
      return (
        <StyledButtonElement
          className={className}
          disabled={disabled}
          error={error}
          icon={icon}
          iconPosition={iconPosition}
          loading={loading}
          onClick={onClick}
          pageColors={pageColors}
          shape={shape}
          size={size}
          success={success}
          bgColor={bgColor}
        >
          <ButtonContent>
            {icon && iconPosition !== 'right' ? this.renderIcon(icon) : false}
            {children}
            {icon && iconPosition === 'right' ? this.renderIcon(icon) : false}
          </ButtonContent>
        </StyledButtonElement>
      )
    }
  }
}

export default Button
