import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import { colors, helpers, typography, mediaQueries as mq } from 'src/styles'

const Wrapper = styled.div`
  padding-left: 1.5em;
  ${ mq.mediumAndUp } {
    padding-left: 2.5em;
  }
  ${ helpers.responsiveStyles('padding-left', 140, 100, 50, 20) }
  ${ helpers.responsiveStyles('padding-right', 140, 100, 50, 20) }
`
const Caption = styled.p `
  position: relative;
  ${ typography.bodyLarge }
  font-weight: 500;
  color: ${ colors.textColor };
`

const ImageCaptionBlock = ({ image, caption, bgColor, prevBgColor, nextBgColor, pageColors }) => (
  <Section bgColor={bgColor} pageColors={pageColors} prevBgColor={prevBgColor} nextBgColor={nextBgColor}>
    <Wrapper>
      <Image image={image} />
      <Caption>
        {caption}
      </Caption>
    </Wrapper>
  </Section>
)

export default ImageCaptionBlock
