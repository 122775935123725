import React from 'react';
import styled from '@emotion/styled';
import Image from 'src/components/Image';
import Parallax from 'src/components/Parallax';
import Section from 'src/components/Section';

import { mediaQueries as mq, globals } from 'src/styles';

const Wrapper = styled.div`
  margin-right: -${globals.containerLargeMargins};
  ${mq.largeAndBelow} {
    margin-right: -${globals.containerMediumMargins};
    margin-left: -${globals.containerMediumMargins};
  }
  ${mq.smallAndBelow} {
    margin-right: -${globals.containerSmallMargins};
    margin-left: -${globals.containerSmallMargins};
  }
`;

const LayerTop = styled(Parallax)`
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 36%;
  ${({ overlapPos }) => (overlapPos === 'left' ? `left: 0;` : `right: 0;`)}
`;

const LayerBottom = styled(Parallax)`
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 15%;
  ${({ overlapPos }) => overlapPos === 'left' && `padding-left: 5%;`}
`;

const ImageOverlap = ({
  overlapPos,
  imageLarge,
  imageSmall,
  bgColor,
  prevBgColor,
  nextBgColor,
  pageColors,
}) => {
  return (
    <Section
      noMarginBottom
      noMarginTop
      zIndex={2}
      width="extra-wide"
      bgColor={bgColor}
      pageColors={pageColors}
      prevBgColor={prevBgColor}
      nextBgColor={nextBgColor}
    >
      <Wrapper style={{ position: 'relative' }}>
        <LayerBottom
          overlapPos={overlapPos}
          posYStart={-5}
          posYEnd={15}
          scrollUnit="%"
        >
          <Image image={{ image: imageLarge }} />
        </LayerBottom>
        <LayerTop
          overlapPos={overlapPos}
          layerIndex={2}
          posYStart={15}
          posYEnd={-20}
          scrollUnit="%"
        >
          <Image image={{ image: imageSmall }} />
        </LayerTop>
      </Wrapper>
    </Section>
  );
};

export default ImageOverlap;
