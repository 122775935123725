import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors, globals, typography, mediaQueries as mq } from 'src/styles';
import Parallax from 'src/components/Parallax';
import ReactMarkdown from 'react-markdown';
import { ResponsiveImage } from 'src/components/Image';
import Link from 'src/components/Link';
import BgSelector from 'src/components/BgSelector';
import Container from 'src/components/Container';
import GridStyles from 'src/components/GridStyles';
import PageContext from 'src/context/PageContext';

const FactWrapper = styled(BgSelector)`
  position: relative;
  ${globals.verticalPadding}
`;
const Grid = styled(GridStyles.Grid)``;
const GridColumn = styled(GridStyles.GridColumn)`
  ${mq.mediumAndUp} {
    -ms-grid-column-span: 8;
    -ms-grid-column: 3;
    grid-column: 3 / span 8;
  }

  ${mq.largeAndUp} {
    -ms-grid-column-span: 7;
    -ms-grid-column: 5;
    grid-column: 5 / span 7;
  }

  ${mq.largerAndUp} {
    -ms-grid-column-span: 6;
    -ms-grid-column: 6;
    grid-column: 6 / span 6;
  }
`;
const FactBoxWrapper = styled.div`
  background-color: ${colors.green};
  color: ${colors.white};
  margin: ${({ hasImage }) => (hasImage ? '80%' : '0')} auto 0;
  padding: 40px 32px;
  position: relative;
  max-width: 640px;

  ${mq.mediumAndUp} {
    margin: 5% auto ${({ hasImage }) => (hasImage ? '20%' : '5%')};
  }
`;
const Headline = styled.h3`
  color: ${colors.yellow};
  ${typography.h3}
  font-weight: 700;
  margin-bottom: 0;
`;
const Blockquote = styled.blockquote`
  margin: 20px 0 0;

  p {
    margin: 0 0 20px;
    ${typography.h3}
    font-weight: 700;

    strong {
      color: ${colors.yellow};
      font-weight: 700;
    }
  }
`;
const Cite = styled.cite`
  ${typography.bodyMedium}
  color: ${colors.yellow};
  font-weight: 600;
`;
const ImageWrapper = styled.div`
  padding: 40px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  overflow: hidden;

  ${mq.mediumAndUp} {
    height: 100%;
  }
`;
const ImageParallax = styled(Parallax)`
  width: 100%;
  height: 100%;
`;
const Image = styled(ResponsiveImage)`
  width: 100%;
  height: 100%;

  > div {
    display: none;
  }

  &.desktop-image {
    display: none;

    ${mq.mediumAndUp} {
      display: block;
    }
  }

  &.mobile-image {
    ${mq.mediumAndUp} {
      display: none;
    }
  }
`;
export default function FactBox({
  title,
  fact,
  source,
  sourceUrl,
  pageColors,
  desktopImage,
  mobileImage,
}) {
  const { lang } = useContext(PageContext);
  return (
    <FactWrapper pageColors={pageColors}>
      <Container>
        <Grid>
          {desktopImage && (
            <ImageWrapper>
              <ImageParallax posYStart={-15} posYEnd={15} scrollUnit="%">
                <Image
                  objectFit="contain"
                  objectPosition="65% 50%"
                  image={{ image: desktopImage }}
                  animate={false}
                  className={mobileImage ? 'desktop-image' : ''}
                />
                {mobileImage && (
                  <Image
                    objectFit="contain"
                    image={{ image: mobileImage }}
                    animate={false}
                    className="mobile-image"
                  />
                )}
              </ImageParallax>
            </ImageWrapper>
          )}
          <GridColumn>
            <FactBoxWrapper hasImage={desktopImage}>
              <Headline>{title}:</Headline>
              <Blockquote>
                <ReactMarkdown children={fact} />
                <Cite>
                  {lang === 'es' ? 'Fuente' : 'Source'}: <Link to={sourceUrl}>{source}</Link>
                </Cite>
              </Blockquote>
            </FactBoxWrapper>
          </GridColumn>
        </Grid>
      </Container>
    </FactWrapper>
  );
}

FactBox.propTypes = {
  title: PropTypes.string,
  fact: PropTypes.string.isRequired,
  source: PropTypes.string,
  bgColor: PropTypes.string,
};
