import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Parallax from 'src/components/Parallax'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollEntrance from 'src/components/ScrollEntrance'

const Layer = styled(Parallax)`
  ${ ({ layerIndex }) => layerIndex !== 1 ? `
    position: absolute;
  ` : `
    position: relative;
  ` }
  
  z-index: ${ ({ layerIndex }) => layerIndex };
  width: 100%;
`

const SizeWrap = styled.div`
  .gatsby-image-wrapper,
  video {
    ${ ({ size }) => size === 'small' ? `
      max-width: 40%;
    ` : `
      max-width: 70%;
    ` }
    
    ${ ({ alignment }) => alignment === 'right' ? `
      margin-left: auto;
    ` : `
      margin-right: auto;
    ` }
    
    ${ ({ withShadow, alignment }) => withShadow ? (alignment === 'left' ? `
      box-shadow: 2px 4px 10px 0 rgba(0,0,0,.3);
    ` : `
      box-shadow: -2px 4px 10px 0 rgba(0,0,0,.3);
    `) : `` }
  }
`

const ImageElement = ({ image, size, alignment, withShadow }) => (
  <SizeWrap size={size} alignment={alignment} withShadow={withShadow}>
    <Image image={{image}} />
  </SizeWrap>
)

const VideoElement = ({ video, size, alignment, withShadow }) => (
  <ScrollEntrance>
    <SizeWrap size={size} alignment={alignment} withShadow={withShadow}>
      <Video video={{ file: video }} style={{ display: 'block' }} />
    </SizeWrap>
  </ScrollEntrance>
)

const MediaCollageElement = ({ data: { type, ...rest }, alignment, withShadow }) => {
  if (type === 'video' && rest.video) {
    return <VideoElement {...rest} size={rest.videoSize} alignment={alignment} withShadow={withShadow}/>
  }

  if (type === 'image' && rest.image) {
    return <ImageElement {...rest} size={rest.imageSize} alignment={alignment} withShadow={withShadow}/>
  }

  return null
}

const MediaCollage = ({ alignment, bgColor, isFirst, ...rest }) => {
  return (
    <Section noMarginBottom noMarginTop={!isFirst} zIndex={2} width="extra-wide" bgColor={bgColor}>
      <div style={{ position: 'relative' }}>
        <ConditionalRender condition={rest.topLayer}>
          <Layer alignment={alignment} layerIndex={2} posYStart={100} posYEnd={-10} scrollUnit="%">
            <MediaCollageElement layerIndex={2} data={rest.topLayer} alignment={alignment} withShadow={true} />
          </Layer>
        </ConditionalRender>
        <ConditionalRender condition={rest.bottomLayer}>
          <Layer layerIndex={1}>
            <MediaCollageElement layerIndex={1} data={rest.bottomLayer} alignment={alignment === `left` ? `right` : `left`}/>
          </Layer>
        </ConditionalRender>
      </div>
    </Section>
  )
}

const MediaPropType = PropTypes.shape({
  localFile: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        sizes: PropTypes.string,
      })
    })
  })
})

const LayerPropTypes = PropTypes.shape({
  type: PropTypes.oneOf([ 'image', 'video' ]).isRequired,
  image: MediaPropType,
  imageSize: PropTypes.string,
  video: MediaPropType,
  videoSize: PropTypes.string
})

MediaCollage.propTypes = {
  alignment: PropTypes.oneOf([ 'left', 'right' ]),
  topLayer: LayerPropTypes,
  bottomLayer: LayerPropTypes
}

export default MediaCollage
