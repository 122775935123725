import React, { useRef } from 'react';
import styled from '@emotion/styled';
import Section from 'src/components/Section';
import { useInView } from 'react-intersection-observer';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import { colors } from 'src/styles';
import VideoPlayer from 'src/components/VideoPlayer';
import 'gsap/CSSPlugin';

const VideoCaseStudyWrapper = styled.div`
  background: rgba(0, 0, 0, 0.9);
  position: relative;
  z-index: 4;
`;

export default function VideoCaseStudyBlock(props) {
  const {
    eyebrow,
    title,
    coverImage,
    coverVideo,
    mediaType,
    prevBgColor,
    nextBgColor,
    videoId,
    className,
    pageColors,
    addHomeVideo,
  } = props;

  const [ref, inView] = useInView({ threshold: 0 });
  const sectionWrapper = useRef(null);

  const onEnter = (node) => {
    const delay = 0.075;
    gsap.fromTo(
      node,
      { duration: 0.6, backgroundColor: `rgba(0,0,0,0)` },
      {
        backgroundColor: `rgba(0,0,0,0.9)`,
        delay,
        ease: 'power2.out',
      }
    );
    gsap.fromTo(
      sectionWrapper.current,
      { duration: 0.3, scale: 0.8, autoAlpha: 0 },
      { scale: 1, autoAlpha: 1, delay, ease: 'power2.inOut' }
    );
  };

  const mainColor = pageColors.mainColor;

  return (
    !addHomeVideo && (
      <Transition in={inView} timeout={0} onEnter={onEnter}>
        <VideoCaseStudyWrapper
          className={className}
          ref={ref}
          inView={inView}
          pageColors={pageColors}
          style={{ visibility: `${inView ? 'visible' : 'hidden'}` }}
        >
          <Section
            bgColor={null}
            pageColors={pageColors}
            prevBgColor={prevBgColor}
            nextBgColor={nextBgColor}
            width="wide-center"
          >
            <div ref={sectionWrapper} style={{ color: colors[mainColor] }}>
              <VideoPlayer
                eyebrow={eyebrow}
                poster={mediaType === 'video' ? coverVideo : coverImage}
                posterType={mediaType}
                title={title}
                videoId={videoId}
              />
            </div>
          </Section>
        </VideoCaseStudyWrapper>
      </Transition>
    )
  );
}
