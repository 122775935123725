import React, { useEffect, Fragment } from 'react';
import styled from '@emotion/styled';
import { helpers, mediaQueries as mq } from 'src/styles';
import { graphql, navigate } from 'gatsby';
import Seo from 'src/components/Seo';
import ComponentRenderer from 'src/components/ComponentRenderer';
import {
  addIsFirstProp,
  addNextPrevBgColorProp,
  checkForHero,
} from 'src/utils/helpers';
import Hero from 'src/components/Hero';
import NextSection from 'src/components/NextSection';
import NextSectionLarge from 'src/components/NextSectionLarge';
import WPSidebarNavigation from 'src/components/WPSidebarNavigation';
import Container from 'src/components/Container';
import StickyBox from 'react-sticky-box';
import GridStyles from 'src/components/GridStyles';

const PageWrapper = styled.div`
  position: relative;
`;
const Sidebar = styled.div`
  ${helpers.responsiveStyles('padding-top', 120, 90, 70, 40)}
  display: none;

  ${mq.largerAndUp} {
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 3;
  }
`;
const Grid = styled(GridStyles.Grid)``;
const GridColumn = styled(GridStyles.GridColumn)`
  ${mq.largeAndUp} {
    -ms-grid-column-span: 4;
    grid-column: span 4;
  }
`;

export default function PageTemplate({ data, location }) {
  const hasHero = checkForHero(data);
  const { page, firstChild, nextSection } = data;

  useEffect(() => {
    if (!page.parentId) {
      navigate(`/${page.slug}/${firstChild.slug}/`);
    }
  }, [firstChild.slug, page.parentId, page.slug]);

  const components = page.designPagesModules.contentBlocks;

  const renderNextSection = () => {
    if (nextSection) {
      if (!page.designPagesModules.largeNextSection) {
        return (
          <NextSection
            title={page.designPagesModules.nextSectionTitle}
            image={{ image: page.designPagesModules.nextSectionImage }}
            link={nextSection.path}
            bgColor={page.designPagesModules.nextSectionTheme}
            pageColors={page.designPagesModules.colorTheme}
          />
        );
      } else {
        return (
          <NextSectionLarge
            title={page.designPagesModules.nextSectionTitle}
            image={{ image: page.designPagesModules.nextSectionImage }}
            link={nextSection.path}
            bgColor={page.designPagesModules.nextSectionTheme}
            pageColors={page.designPagesModules.colorTheme}
            textAccent={page.designPagesModules.nextSectionTextAccentColor}
          />
        );
      }
    }
  };

  return (
    <>
      <Seo meta={page.meta} />
      {hasHero && (
        <Hero
          slug={page.designPagesModules.heroSlug}
          headline={page.designPagesModules.headline}
          text={page.designPagesModules.body}
          type={page.designPagesModules.heroType}
          video={page.designPagesModules.heroVideo}
          image={
            page.designPagesModules.heroImageMobile
              ? {
                  small: page.designPagesModules.heroImageMobile,
                  large: page.designPagesModules.heroImage,
                }
              : {
                  image: page.designPagesModules.heroImage,
                }
          }
        />
      )}
      <PageWrapper>
        <Sidebar>
          <StickyBox offsetTop={100}>
            <Container>
              <Grid>
                <GridColumn>
                  <WPSidebarNavigation
                    location={location}
                    pageColors={page.designPagesModules.colorTheme}
                  />
                </GridColumn>
              </Grid>
            </Container>
          </StickyBox>
        </Sidebar>
        <div>
          {components
            ? components.map((c, index) => {
                addIsFirstProp(c, index);
                addNextPrevBgColorProp(c, index, components);
                return (
                  <ComponentRenderer
                    key={index}
                    item={c}
                    pageColors={page.designPagesModules.colorTheme}
                  />
                );
              })
            : false}
        </div>
      </PageWrapper>
      {renderNextSection()}
    </>
  );
}

export const pageQuery = graphql`
  query pageQuery($id: String!, $firstChild: String!, $nextSection: String!, $langSyncId: String, $lang: String) {
    secondaryLang: wpDesignPage(
      designPagesModules: {
        langSyncId: {eq: $langSyncId}
      }
      language: {slug: {ne: $lang}}
    ) {
      title
      uri
    }
    page: wpDesignPage(id: { eq: $id }) {
      title
      designPagesModules {
        langSyncId
      }
      id
      parentId
      slug
      path: uri
      designPagesModules {
        colorTheme {
          mainColor
        }
        pageAudio {
          id
          sourceUrl
        }
        heroSlug
        heroType
        heroImage {
          id
          alt: altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        heroImageMobile {
          id
          alt: altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        heroVideo {
          file {
            localFile {
              publicURL
            }
          }
          placeholderImage {
            localFile {
              publicURL
            }
          }
        }
        nextSectionTitle
        nextSectionTheme
        nextSectionTextAccentColor
        largeNextSection
        nextSectionImage {
          alt: altText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        headline
        body
        contentBlocks {
          ...Text
          ...ImageCollage
          ...MediaCollage
          ...VideoCaseStudy
          ...CardsSlideshow
          ...ReadMoreBlock
          ...ImageOverlap
          ...ActivityBlock
          ...ArrowLink
          ...AudioPlayer
          ...ImageCaptionBlock
          ...FactBox
        }
      }
      meta: seo {
        canonical
        focuskw
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1200
                height: 630
                placeholder: BLURRED
                layout: FIXED
              )
            }
          }
        }
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphAuthor
        opengraphType
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                height: 512
                placeholder: BLURRED
                layout: FIXED
              )
            }
          }
        }
        twitterTitle
      }
    }
    firstChild: wpDesignPage(id: { eq: $firstChild }) {
      slug
    }
    nextSection: wpDesignPage(id: { eq: $nextSection }) {
      path: uri
    }
  }
`;
