import React from 'react'
import { ScrollPercentage } from 'react-scroll-percentage';
import styled from '@emotion/styled'
import { gsap } from 'gsap';

const Container = styled.div`
  > div {
    display: inline;
  }
`

const Parallax = ({ children, className, posYStart, posYEnd, scrollUnit, rotateStart, rotateEnd, scaleStart, scaleEnd, opacityStart, opacityEnd }) => {
  const parallaxOffset = (num, outMin, outMax) => {
    let offset = (num - 0) * (outMax - outMin) / (1 - 0) + outMin
    if (!offset) {
      return 0
    } else {
      return offset.toPrecision(5)
    }
  }

  const onChange = (percentage, entry)  => {
    const el = entry.target.parentNode;
    const { offsetHeight } = el;
    const unitIsPercentage = scrollUnit === '%';
    const posYStartPx = unitIsPercentage ? posYStart/100 * offsetHeight : posYStart;
    const posYEndPx = unitIsPercentage ? posYEnd/100 * offsetHeight : posYEnd;
    gsap.set(el, {
      rotation: parallaxOffset(percentage, rotateStart, rotateEnd),
      scale: parallaxOffset(percentage, scaleStart, scaleEnd),
      autoAlpha: parallaxOffset(percentage, opacityStart, opacityEnd),
      y: parallaxOffset(percentage, posYStartPx, posYEndPx)
    });
  }

  return (
    <Container className={className}>
      <ScrollPercentage onChange={onChange}>
        {children}
      </ScrollPercentage>
    </Container>
  )
}

Parallax.defaultProps = {
  posYStart: 0,
  posYEnd: 0,
  rotateStart: 0,
  rotateEnd: 0,
  scaleStart: 1,
  scaleEnd: 1,
  scrollUnit: 'px',
  opacityStart: 1,
  opacityEnd: 1
}

export default Parallax
