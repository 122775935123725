import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import ConditionalRender from 'src/components/ConditionalRender'
import Section from 'src/components/Section'
import Arrow from 'src/svg/icon-arrow.svg'
import { helpers, typography, colors, animations } from 'src/styles'

const ArrowLink = (props) => {
  const {
    text,
    to,
    external,
    bgColor,
    noMarginTop,
    noMarginBottom,
    prevBgColor,
    nextBgColor,
    pageColors
  } = props;

  return (
    <ConditionalRender condition={to && text}>
      <Section bgColor={bgColor} pageColors={pageColors} noMarginTop={noMarginTop} noMarginBottom={noMarginBottom} prevBgColor={prevBgColor} nextBgColor={nextBgColor}>
        <StyledLink to={to} external={external} >
          <Arrow /> <p>{text}<span /></p>
        </StyledLink>
      </Section>
    </ConditionalRender>
  )
}

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;

  p {
    ${ typography.bodyLarge }
    display: block;
    margin-top: -2px;
    margin: 0;
    padding-right: 0;
    position: relative;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
    width: auto;

    span {
      background: ${ colors.green };
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      top: 102%;
      transform-origin: left center;
      transform: scaleX(0);
      transition: transform ${ animations.mediumSpeed } ease-in-out;
    }
  }

  &:hover {
    p {
      transform: translate3d(20px, 0, 0);

      span {
        transform: none;
      }
    }

    svg {
      * {
        fill: ${ colors.greenDarken };
      }
    }
  }

  svg {
    ${ helpers.responsiveStyles('width', 60, 50, 40, 20)}
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;
    margin-right: .75em;

    * {
      fill: ${ colors.green };
      transition: fill ${ animations.mediumSpeed} ease-in-out;
    }
  }
`

ArrowLink.defaultProps = {
  external: false
}

export default ArrowLink
